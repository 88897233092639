import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "../styles/main.scss"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import WhyTint from "../components/WhyTint"
import PageCarousel from "../components/PageCarousel"

export default function Home() {
  const bannerData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/car-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sliderImages = [
    { alt: "Car window tinting", fileName: "DSCF0779.jpg" },
    { alt: "Bus window tinting", fileName: "DSCF1624.jpg" },
    { alt: "Truck window tinting", fileName: "DSCF1993.jpg" },
  ]

  return (
    <>
      <Helmet>
        <title>Automotive Tinting | Window Magic</title>
        <meta
          name="description"
          content="Tinting vehicles provides benefits like protection and privacy. All of our tints come with a full manufacturer warranty."
        />
      </Helmet>

      <Layout hidePageHeader={true}>
        <PageHeader
          fluid={bannerData.file.childImageSharp.fluid}
          alt="Car with window tints"
          title="Automotive"
        />

        <section className="page-section">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-6">
                <p>
                  Since 21 October 1999 it has been legal in New Zealand to
                  apply film to any window on a vehicle except the front screen.
                  The degree of darkness that is allowed depends on the
                  classification of your vehicle. Generally speaking cars are
                  allowed 35% on all glass except the front screen and other
                  vehicles are allowed dark behind the driver. Please{" "}
                  <a href="/contact/">contact us</a> for clarification.
                </p>
                <p>
                  At our tint shop in Plimmerton we can tint different vehicles
                  with different types of tint depending on what your needs are.
                  With 30 years of experience workmanship is guaranteed and the
                  tints have at least 10 year warrantees.
                </p>
                <p>
                  Come in and look at the large photo display of some of the
                  many vehicles we have transformed by tinting the windows.
                </p>
                <p>
                  Feel free to relax in the waiting room and have a cuppa while
                  we tint your vehicle.
                </p>
                <p>
                  We also certify the glass with 35% VLT (Visible Light
                  Transmission) stickers to help avoid any hassle at the warrant
                  test or on the road.
                </p>
                <p>
                  An on-site car-tinting service is available for a $50 travel
                  fee, however some conditions apply and we recommend bringing
                  your vehicle in to get the best possible job.
                </p>
              </div>
              <div className="col-lg-5 d-flex flex-column justify-content-center">
                <PageCarousel imagesToDisplay={sliderImages} />
              </div>
            </div>
          </div>
        </section>

        <WhyTint />
      </Layout>
    </>
  )
}
